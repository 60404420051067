<template>
  <div class="PublishableList">
    <div class="f-c-b">
      <el-page-header @back="backPrivious" :content="contentTitle"></el-page-header>
      <el-button class="publish-btn" type="primary" @click="HandlePublish">发布</el-button>
    </div>
    <div class="list_item f-c-b" v-for="(item, index) of list" :key="index" @click="jumpDetail(item.postId)">
      <div class="info f-cl">
        <span>{{ item.postHead }}</span>
        <div class="public_people f-c">
          <img :src="item.userAvatarUrl" alt="">
          <span>{{ item.userName }}</span>
        </div>
        <span class="previewContent">{{ item.previewContent }}</span>
      </div>
      <el-image v-if="item.mobject1Type == 'jpg' || item.mobject1Type == 'png'" style="width: 220px; height: 110px"
        :src="item.mobject1" fit="cover" :alt="item.postHead" lazy></el-image>
      <span v-else></span>
    </div>
    <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout" @pagination="fetchData" />
  </div>
</template>

<script>


import { UrlDecodeType } from '@/mixins/UrlDecodeMixin';
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';

export default {
  name: 'PublishableList',
  mixins: [UrlDecodeType, PaginationRetention],
  data() {
    return {
      contentTitle: '',
      list: [],
    }
  },
  created() {
    switch (this.queryType) {
      case 'IndustryTrends':
        this.contentTitle = '行业动态'
        break;
      case 'SupplyAndPurchase':
        this.contentTitle = '供应求购';
        break;
      case 'ProductExchange':
        this.contentTitle = '产品交流';
        break;
      case 'TalentRecruitment':
        this.contentTitle = '人才招聘';
        break;
      case 'ShiLiaoZaTan':
        this.contentTitle = '识料杂谈';
        break;
      case 'SuggestedFeedback':
        this.contentTitle = '建议反馈';
        break;
      case 'MyPurchase':
        this.contentTitle = '我的求购';
        break;
    }
  },
  methods: {
    getDate() {
      switch (this.queryType) {
        case 'ProductExchange':
          this.forumArticleList(this.paging, this.pagingSize)
          break;
        case 'ShiLiaoZaTan':
          this.forumChatArticleList(this.paging, this.pagingSize)
          break;
        case 'SupplyAndPurchase':
          this.forumBuySearchList(this.paging, this.pagingSize)
          break;
        case 'MyPurchase':
          this.interiorForumBuySearchList(this.paging, this.pagingSize)
          break;
      }
    },
    // 点击发布按钮
    HandlePublish() {
      switch (this.queryType) {
        // 产品交流 识料杂谈
        case 'ProductExchange':
        case 'ShiLiaoZaTan':
          this.$router.push({
            path: '/PostEdit',
            query: {
              key: this.$UrlEncode.encode(JSON.stringify({
                type: this.queryType
              }))
            }
          })
          break;
        // 供应求购
        case 'SupplyAndPurchase':
        case 'MyPurchase':
          this.$router.push('/SupplyAndPurchasePublish')
          break;
      }
    },
    // 获取参数
    getParams(page, size) {
      let that = this;
      return {
        postUserId: parseInt(that.$store.state.userId),
        token: that.$getStorage('token'),
        postHead: '',
        page,
        size
      }
    },
    // 处理返回结果
    handleResult(res) {
      let that = this;
      if (res.code == 200) {
        res.data.records.forEach(item => {
          if (item.mobject1) {
            let imgIndex = item.mobject1.lastIndexOf("."),//获取分隔符最后出现的位置
              ext = item.mobject1.substr(imgIndex + 1);//从分隔符位置开始截取，得到的是如：jpg，png，jpeg等
            item.mobject1Type = ext
          }
          item.previewContent = item.previewContent ? item.previewContent + '...' : item.previewContent
        })
        that.list = res.data.records
        that.currentPage = res.data.current;//当前页数
        that.total = res.data.total
      }
    },
    // 产品交流帖子搜索列表接口
    forumArticleList(page = this.page, size = this.size) {
      let that = this;
      that.$http.forumArticleList(that.getParams(page, size)).then((res) => {
        that.handleResult(res)
      })
    },
    // 识料杂谈帖子搜索列表接口
    forumChatArticleList(page = this.page, size = this.size) {
      let that = this;
      that.$http.forumChatArticleList(that.getParams(page, size)).then((res) => {
        that.handleResult(res)
      })
    },
    //  供应求购帖子搜索列表接口
    forumBuySearchList(page = this.page, size = this.size) {
      let that = this;
      that.$http.forumBuySearchList(that.getParams(page, size)).then((res) => {
        that.handleResult(res)
      })
    },
    //  用户查看自己的供应求购帖子列表接口
    interiorForumBuySearchList(page = this.page, size = this.size) {
      let that = this;
      that.$http.interiorForumBuySearchList(that.getParams(page, size)).then((res) => {
        that.handleResult(res)
      })
    },
    // 产品交流帖子详情
    jumpDetail(postId) {
      switch (this.queryType) {
        case 'ProductExchange':
        case 'ShiLiaoZaTan':
          this.$router.push({
            name: 'PostDetail',
            query: {
              key: this.$UrlEncode.encode(JSON.stringify({
                type: this.queryType,
                id: postId
              }))
            }
          })
          break;
        case 'SupplyAndPurchase':
        case 'MyPurchase':
          this.$router.push({
            name: 'SupplyAndPurchaseDetail',
            query: {
              key: this.$UrlEncode.encode(JSON.stringify({
                id: postId,
                type: this.queryType
              }))
            }
          })
          break;
      }
    }

  },
}

</script>
<style lang='less' scoped>
.PublishableList {
  .publish-btn {
    align-self: flex-end;
    margin-bottom: 15px;
  }

  .list_item {
    padding: 15px;
    border-bottom: 1px solid #eee;

    img {
      width: 220px;
      height: 100px;
    }

    .info {
      span:first-child {
        font-size: 20px;
        font-weight: bold;
      }

      .previewContent {
        width: 90%;

      }

      .public_people {
        margin: 15px 0;

        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        span {
          margin-left: 10px;
        }
      }
    }
  }

  .list_item:hover {
    background-color: rgb(233, 250, 255);
    cursor: pointer;
  }
}
</style>
